<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3>Editar Parametro de la Aplicación</h3>
      </CCardHeader>
      <CCardBody>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
              class="contact-form"
              method="post"
              v-on:submit.prevent="handleSubmit(actualizar)"
          >
            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Etiqueta:
                  </label>
                  <validation-provider
                      name="Etiqueta"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <input
                        type="text"
                        v-model="registro.etiqueta"
                        class="form-control"
                    />
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Valor:
                  </label>
                  <validation-provider
                      name="Valor"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <input
                        type="text"
                        v-model="registro.valor"
                        class="form-control"
                    />
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>


            <div class="form-group">
              <button type="button" class="btn btn-primary mr-2" @click="actualizar()">
                <i class="fa fa-refresh" aria-hidden="true"></i>
                Actualizar
              </button>

              <router-link to="/configuracionApp/List">
                <CButton color="secondary">Cancelar</CButton>
              </router-link>
            </div>
          </form>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import httpService from "@/services/httpService"

export default {
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {
        id: '',
        valor: '',
        etiqueta: ""
      }
    };
  },

  methods: {
    async getRegistro() {

      this.id = this.$route.params.id;
      if (this.id !== "0") {
        let url = "app-configuraciones/" + this.id;
        let response = await httpService.get(url);
        let respuesta = response.data;
        this.registro = respuesta.registros[0];


      }
    },
    async actualizar() {
      try {

        let url = "app-configuraciones/" + this.id;
        let response = await httpService.put(url, this.registro);


        this.$vToastify.success("Operación realizada con éxito", "Éxito");
        this.$router.back()
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.getRegistro();
  }
};
</script>